import React, { useCallback, useContext, useEffect, useState } from "react";
import { BestSelect, Input } from "best-common-react";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import { isCurrentOrPastTournamentYear } from "../../utils/WBCYearPredicates";
import { SendContainer, SendContents, FiltersContainer, Filter, YearFilter } from "./EmailNotificationsStyles";
import EmailNotificationsApi from "../../httpClients/EmailNotificationsApi";
import EmailNotificationsContext from "../../contexts/EmailNotificationsContext";
import { useAlert } from "../hooks/useAlert";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import styled from "styled-components";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import CountryContext from "../../contexts/CountryContext";
import AuthContext from "../../contexts/AuthContext";

const SelectionSummary = styled.div`
  margin-right: 2rem;
  font-size: 0.75rem;
  font-style: italic;
  color: #bbb;
`;

const EmailNotificationsHeader = () => {
  const { wbcYears, selectedWbcYear, changeSelectedYear } = useContext(WBCYearsContext);
  const {
    emailIdsToSend,
    nameSearchFilter,
    setNameSearchFilter,
    notificationFilter,
    setNotificationFilter,
    setEmails,
    setEmailIdsToSend,
    setIsSending,
    countryFilter,
    setCountryFilter,
    statusFilter,
    setStatusFilter
  } = useContext(EmailNotificationsContext);
  const showAlert = useAlert();
  const [notificationOptions, setNotificationOptions] = useState([]);
  const [fedTeams, setFedTeams] = useState([]);
  const { countries } = useContext(CountryContext).state;
  const { isBOCadmin } = useContext(AuthContext).state;

  const yearOptions = wbcYears.filter(isCurrentOrPastTournamentYear);

  const loadEmailNotificationsByYear = async () => {
    if (selectedWbcYear?.year) {
      EmailNotificationsApi.getNotificationsByYear(selectedWbcYear.year).then(
        res => {
          setEmails(res);
        },
        () => {
          showAlert("Failed to load notifications", "danger");
        }
      );
      const allNotif = { label: "All Notification Types", value: null };
      EmailNotificationsApi.getNotificationTypes().then(notif => {
        setNotificationOptions([allNotif, ...notif.map(n => ({ label: n.name, value: n.name }))]);
      });
    }
  };
  const statusOpts = [
    { label: "All Statuses", value: null },
    { label: "All Sent Notifications", value: 0 },
    { label: "Sent to FED", value: 1 },
    { label: "Sent to CLUB", value: 2 },
    { label: "Unsent Notifications", value: 3 }
  ];

  useEffect(() => {
    const feds = [];
    if (selectedWbcYear?.year && countries) {
      WBCTeamApi.getFederationTeamsByYear(selectedWbcYear?.year).then(res => {
        const teams = res?.forEach(fed => {
          let country = countries.find(c => c?.countryId === fed?.countryId);
          feds.push({ label: country?.countryName, value: fed?.teamId });
        });
        feds.sort((a, b) => a?.label?.localeCompare(b?.label));
      });
      feds.unshift({ label: "All Federations", value: null });

      setFedTeams(feds);
    }
  }, [selectedWbcYear, countries]);

  const handleNameSearchFilter = search => setNameSearchFilter(search?.target?.value);
  const handleNotificationFilter = filter => setNotificationFilter(filter);
  const handleCountryFilter = filter => setCountryFilter(filter);
  const handleStatusFilter = filter => setStatusFilter(filter);

  const handleSend = () => {
    setIsSending(true);
    EmailNotificationsApi.sendNotifications(emailIdsToSend).then(
      res => {
        if (res?.failedNotificationIds?.length > 0) {
          showAlert(
            `Failed to send ${res?.failedNotificationIds?.length} email${
              res?.failedNotificationIds?.length > 1 ? "s" : ""
            }`,
            "danger"
          );
        }
        if (res?.successfulNotificationIds?.length > 0) {
          showAlert(
            `Sent ${res?.successfulNotificationIds?.length} email${
              res?.successfulNotificationIds?.length > 1 ? "s" : ""
            }`,
            "success"
          );
        }
        loadEmailNotificationsByYear().then(() => setEmailIdsToSend([]));
        setIsSending(false);
      },
      () => {
        showAlert(`Failed to send email${emailIdsToSend?.length > 1 ? "s" : ""}`, "danger");
        setIsSending(false);
      }
    );
  };

  const handleWBCYearChange = useCallback(({ year }) => changeSelectedYear(year), [changeSelectedYear]);

  useEffect(() => {
    loadEmailNotificationsByYear();
  }, [selectedWbcYear]);

  return (
    <>
      <FiltersContainer>
        <YearFilter>
          <BestSelect
            name="yearDropdown"
            value={selectedWbcYear}
            options={yearOptions}
            onChange={handleWBCYearChange}
          />
        </YearFilter>
        <Filter>
          <BestSelect
            name="notifTypes"
            value={notificationOptions.find(n => n.value === notificationFilter.value)}
            options={notificationOptions}
            onChange={handleNotificationFilter}
          />
        </Filter>
        <Filter>
          <BestSelect
            name="federations"
            value={fedTeams.find(f => f.value === countryFilter.value)}
            options={fedTeams}
            onChange={handleCountryFilter}
          />
        </Filter>
        <Filter>
          <BestSelect
            name="sentStatus"
            value={statusOpts.find(f => f?.value === statusFilter?.value)}
            options={statusOpts}
            onChange={handleStatusFilter}
          />
        </Filter>
        <Filter>
          <Input placeholder="type to search" value={nameSearchFilter} onChange={handleNameSearchFilter}></Input>
        </Filter>
        <Filter></Filter>
      </FiltersContainer>
      {isBOCadmin && (
        <SendContainer>
          <SelectionSummary>{emailIdsToSend.length} selected</SelectionSummary>
          <WBCPrimaryButton disabled={!emailIdsToSend?.length} onClick={handleSend}>
            <SendContents>
              <i className={"fas fa-paper-plane mt-1"}></i>Send
            </SendContents>
          </WBCPrimaryButton>
        </SendContainer>
      )}
    </>
  );
};

export default EmailNotificationsHeader;
